import React from 'react';

export function TermsPage() {
  return (
    <div className="py-16 bg-gray-50">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Términos y Condiciones</h1>
        
        <div className="prose prose-blue max-w-none bg-white p-8 rounded-xl shadow-md">
          <p className="text-gray-600 mb-6">
            Al utilizar los servicios de <strong>Distribuidora de Químicos de Veracruz</strong> (<strong>DiQV</strong>), usted acepta los términos y condiciones descritos en este documento. Estos términos regulan el acceso y uso de nuestros servicios, así como las responsabilidades de ambas partes.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Servicios Ofrecidos</h2>
          <p className="text-gray-600 mb-6">
            <strong>DiQV</strong> proporciona servicios de distribución y transporte de productos químicos, tales como:
          </p>
          <ul className="list-disc list-inside mb-6 text-gray-600">
            <li>Hipoclorito de sodio</li>
            <li>Ácido sulfúrico</li>
            <li>Gas cloro</li>
          </ul>
          <p className="text-gray-600 mb-6">
            Todos los servicios se realizan cumpliendo con las normativas aplicables, incluyendo aquellas relacionadas con seguridad, transporte y almacenamiento de materiales peligrosos.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Responsabilidades de las Partes</h2>
          <ul className="list-disc list-inside mb-6 text-gray-600">
            <li><strong>DiQV</strong> se compromete a cumplir con todas las normativas legales y de seguridad aplicables.</li>
            <li>El cliente debe proporcionar información precisa sobre el uso final del producto solicitado.</li>
            <li>El cliente debe asegurarse de que las instalaciones sean adecuadas para el almacenamiento seguro de los productos entregados.</li>
            <li><strong>DiQV</strong> no será responsable por el uso inadecuado o negligente de los productos suministrados.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Condiciones de Uso</h2>
          <p className="text-gray-600 mb-6">
            Los productos y servicios ofrecidos por <strong>DiQV</strong> están destinados únicamente para los fines especificados por el cliente. Cualquier uso indebido, redistribución no autorizada o manipulación contraria a las normativas será responsabilidad exclusiva del cliente.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Modificaciones y Actualizaciones</h2>
          <p className="text-gray-600 mb-6">
            <strong>DiQV</strong> se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Las modificaciones entrarán en vigor inmediatamente después de su publicación en el sitio web oficial o mediante notificación directa a los clientes registrados.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Jurisdicción y Ley Aplicable</h2>
          <p className="text-gray-600 mb-6">
            Este acuerdo se rige por las leyes de los Estados Unidos Mexicanos. Cualquier controversia derivada del uso de los servicios será resuelta en los tribunales competentes de Xalapa, Veracruz.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Contacto</h2>
          <p className="text-gray-600">
            Si tiene preguntas o inquietudes relacionadas con estos términos y condiciones, puede contactarnos en:<br />
            <strong>Email:</strong> contacto@diqv.mx<br />
            <strong>Teléfono:</strong> +52 228 235 6878
          </p>
        </div>
      </div>
    </div>
  );
}
