import React from 'react';

export function PrivacyPage() {
  return (
    <div className="py-16 bg-gray-50">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Aviso de Privacidad</h1>
        
        <div className="prose prose-blue max-w-none bg-white p-8 rounded-xl shadow-md">
          <p className="text-gray-600 mb-6">
            Distribuidora de Quimicos de Veracruz, S.A. de C.V. (<strong>DiQV</strong>), 
            con domicilio en <strong>Juan Escutia 7, Col. 21 de Marzo, Xalapa, Ver.</strong>, 
            es responsable de la recolección, uso y protección de sus datos personales, 
            conforme a lo establecido en la Ley Federal de Protección de Datos Personales en 
            Posesión de los Particulares.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Datos Personales que Recabamos</h2>
          <ul className="list-disc list-inside mb-6 text-gray-600">
            <li>Nombre completo</li>
            <li>Correo electrónico</li>
            <li>Teléfono</li>
            <li>Empresa y cargo</li>
            <li>Industria</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Finalidad del Tratamiento</h2>
          <p className="text-gray-600 mb-6">
            Sus datos personales serán utilizados para:
          </p>
          <ul className="list-disc list-inside mb-6 text-gray-600">
            <li>Dar seguimiento a solicitudes de información o cotizaciones</li>
            <li>Procesar pedidos y gestionar entregas de productos</li>
            <li>Brindar asesoría y soporte técnico</li>
            <li>Enviar notificaciones sobre actualizaciones de productos y servicios</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Derechos ARCO</h2>
          <p className="text-gray-600 mb-6">
            Usted tiene derecho a conocer qué datos personales poseemos (Acceso), 
            solicitar la actualización o corrección de los mismos (Rectificación), 
            solicitar su eliminación de nuestros registros cuando así lo requiera 
            (Cancelación), u oponerse al uso de sus datos (Oposición).
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contacto</h2>
          <p className="text-gray-600">
            Para ejercer sus derechos ARCO o para cualquier duda relacionada con este 
            Aviso de Privacidad, puede contactarnos a través de:
            <br />
            <strong>Email:</strong> privacidad@diqv.mx
            <br />
            <strong>Teléfono:</strong> +52 228 235 6878
          </p>
        </div>
      </div>
    </div>
  );
}
