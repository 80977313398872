import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FileText, Shield, Loader2 } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { Database } from '../lib/database.types';

type Product = Database['public']['Tables']['products']['Row'];

export function ProductsPage() {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchProducts() {
      try {
        setLoading(true);
        setError(null);
        
        const { data, error: supabaseError } = await supabase
          .from('products')
          .select('*')
          .order('name');

        if (supabaseError) throw supabaseError;
        setProducts(data || []);
      } catch (err) {
        setError('Error al cargar los productos. Por favor, intente nuevamente.');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    }

    fetchProducts();
  }, []);

  return (
    <div className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Nuestros Productos
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Soluciones químicas especializadas con los más altos estándares de calidad y seguridad
          </p>
        </div>

        {/* Error Message */}
        {error && (
          <div className="mb-8 bg-red-50 border-l-4 border-red-500 p-4">
            <p className="text-red-700">{error}</p>
          </div>
        )}

        {/* Loading State */}
        {loading && (
          <div className="flex justify-center items-center py-12">
            <Loader2 className="w-8 h-8 text-brand-blue animate-spin" />
          </div>
        )}

        {/* Content */}
        {!loading && !error && (
          products.length > 0 ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {products.map((product) => (
                <div
                  key={product.id}
                  className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                >
                  <div className="p-6">
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      {product.name}
                    </h3>
                    <p className="text-gray-600 mb-4">{product.description}</p>
                    
                    {/* Certifications */}
                    <div className="space-y-2 mb-6">
                      {product.certifications?.map((cert) => (
                        <div key={cert} className="flex items-center text-sm text-gray-500">
                          <Shield className="w-4 h-4 text-brand-blue mr-2" />
                          {cert}
                        </div>
                      ))}
                    </div>

                    {/* Actions */}
                    <div className="flex items-center justify-between">
                      {product.sds_url && (
                        <a
                          href={product.sds_url}
                          className="flex items-center text-brand-blue hover:text-blue-700"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FileText className="w-4 h-4 mr-2" />
                          <span className="text-sm font-medium">Descargar SDS</span>
                        </a>
                      )}
                      <Link
                        to="/contacto"
                        className="text-sm font-medium text-white bg-brand-blue px-4 py-2 rounded-lg hover:bg-blue-900 transition-colors"
                      >
                        Solicitar información
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <p className="text-gray-600">No se encontraron productos disponibles.</p>
            </div>
          )
        )}
      </div>
    </div>
  );
}