import { supabase } from './supabase';

interface MediaEntry {
  url: string;
  alt: string;
  description: string | null;
}

export async function getMediaEntry(key: string): Promise<MediaEntry | null> {
  try {
    const { data, error } = await supabase
      .from('media')
      .select('url, description')
      .eq('key', key)
      .single();

    if (error || !data) {
      console.error('Error fetching media entry:', error);
      return null;
    }

    return {
      url: data.url,
      alt: data.description || 'Imagen de DiQV',
      description: data.description
    };
  } catch (err) {
    console.error('Error fetching media entry:', err);
    return null;
  }
}

export async function getMediaEntries(keys: string[]): Promise<Record<string, MediaEntry>> {
  try {
    const { data, error } = await supabase
      .from('media')
      .select('key, url, description')
      .in('key', keys);

    if (error || !data) {
      console.error('Error fetching media entries:', error);
      return {};
    }

    return data.reduce((acc, entry) => ({
      ...acc,
      [entry.key]: {
        url: entry.url,
        alt: entry.description || 'Imagen de DiQV',
        description: entry.description
      }
    }), {});
  } catch (err) {
    console.error('Error fetching media entries:', err);
    return {};
  }
}