import React, { useState, useRef } from 'react';
import { supabase } from '../lib/supabase';
import { AlertTriangle, Loader2 } from 'lucide-react';

const PRODUCT_OPTIONS = [
  'Hipoclorito de Sodio',
  'Ácido Sulfúrico',
  'Gas Cloro',
  'Otro producto',
];

const INDUSTRY_OPTIONS = [
  'Tratamiento de Agua',
  'Minería',
  'Industria Alimentaria',
  'Manufactura',
  'Agricultura',
  'Otra industria',
];

export function ContactForm() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    setSuccess(false);
    setError(null);

    const formData = new FormData(e.currentTarget);
    const data = {
      name: formData.get('name') as string,
      company: formData.get('company') as string,
      industry: formData.get('industry') as string,
      email: formData.get('email') as string,
      phone: formData.get('phone') as string,
      product_interest: formData.get('product_interest') as string,
      message: formData.get('message') as string
    };

    try {
      const { error: supabaseError } = await supabase
        .from('contact_form')
        .insert([data]);

      if (supabaseError) {
        console.error('Supabase error:', supabaseError);
        throw new Error(supabaseError.message || 'Error al enviar el formulario');
      }

      setSuccess(true);
      formRef.current?.reset();
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Error al enviar el formulario';
      setError(`${errorMessage}. Por favor, intente nuevamente.`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit} className="space-y-6 max-w-2xl mx-auto">
      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-r-md">
          <div className="flex items-center">
            <AlertTriangle className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
            <p className="text-red-700">{error}</p>
          </div>
        </div>
      )}

      {success && (
        <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded-r-md">
          <p className="text-green-700">
            ¡Gracias por contactarnos! Su mensaje ha sido enviado correctamente. Nuestro equipo se pondrá en contacto con usted pronto.
          </p>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Nombre completo *
          </label>
          <input
            type="text"
            name="name"
            id="name"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-blue focus:ring-brand-blue"
          />
        </div>

        <div>
          <label htmlFor="company" className="block text-sm font-medium text-gray-700">
            Empresa *
          </label>
          <input
            type="text"
            name="company"
            id="company"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-blue focus:ring-brand-blue"
          />
        </div>

        <div className="md:col-span-2">
          <label htmlFor="industry" className="block text-sm font-medium text-gray-700">
            Industria *
          </label>
          <select
            name="industry"
            id="industry"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-blue focus:ring-brand-blue"
          >
            <option value="">Seleccione su industria</option>
            {INDUSTRY_OPTIONS.map((industry) => (
              <option key={industry} value={industry}>
                {industry}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Correo electrónico *
          </label>
          <input
            type="email"
            name="email"
            id="email"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-blue focus:ring-brand-blue"
          />
        </div>

        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
            Teléfono *
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-blue focus:ring-brand-blue"
          />
        </div>

        <div className="md:col-span-2">
          <label htmlFor="product_interest" className="block text-sm font-medium text-gray-700">
            Producto de interés *
          </label>
          <select
            name="product_interest"
            id="product_interest"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-blue focus:ring-brand-blue"
          >
            <option value="">Seleccione un producto</option>
            {PRODUCT_OPTIONS.map((product) => (
              <option key={product} value={product}>
                {product}
              </option>
            ))}
          </select>
        </div>

        <div className="md:col-span-2">
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            Mensaje *
          </label>
          <textarea
            name="message"
            id="message"
            rows={4}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-blue focus:ring-brand-blue"
          />
        </div>

      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={submitting}
          className="bg-brand-blue text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-900 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
        >
          {submitting ? (
            <>
              <Loader2 className="w-5 h-5 mr-2 animate-spin" />
              Enviando...
            </>
          ) : (
            'Enviar mensaje'
          )}
        </button>
      </div>
    </form>
  );
}