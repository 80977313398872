import React from 'react';
import { ContactForm } from '../components/ContactForm';
import { MapPin, Phone, Mail } from 'lucide-react';

export function ContactPage() {
  return (
    <div className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Contáctenos</h1>
          <p className="text-xl text-gray-600">
            Estamos aquí para responder sus preguntas y atender sus necesidades
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          <div className="bg-white p-6 rounded-xl shadow-md text-center">
            <div className="w-12 h-12 bg-brand-blue/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Phone className="w-6 h-6 text-brand-blue" />
            </div>
            <h3 className="font-semibold mb-2">Teléfono</h3>
            <p className="text-gray-600">+52 228 235 6878</p>
          </div>

          <div className="bg-white p-6 rounded-xl shadow-md text-center">
            <div className="w-12 h-12 bg-brand-blue/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Mail className="w-6 h-6 text-brand-blue" />
            </div>
            <h3 className="font-semibold mb-2">Email</h3>
            <p className="text-gray-600">contacto@diqv.mx</p>
          </div>

          <div className="bg-white p-6 rounded-xl shadow-md text-center">
            <div className="w-12 h-12 bg-brand-blue/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <MapPin className="w-6 h-6 text-brand-blue" />
            </div>
            <h3 className="font-semibold mb-2">Dirección</h3>
            <p className="text-gray-600">
              Juan Escutia 7, Col. 21 de Marzo, Xalapa, Ver.
              <br />
              <a 
                href="https://maps.app.goo.gl/YU4sq8zWVV2hm9jV7" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-brand-blue hover:underline mt-1 inline-block"
              >
                Ver en Google Maps
              </a>
            </p>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-8">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}