import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Truck, Shield, MapPin, Phone } from 'lucide-react';
import mapboxgl, { Map as MapboxMap } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { getMediaEntries } from '../lib/supabase-storage';
import { LOCATIONS, ROUTES, MAP_CONFIG, MAPBOX_TOKEN } from '../lib/mapConfig';

if (!MAPBOX_TOKEN) {
  console.error('Error: Mapbox token not found.');
} else {
  mapboxgl.accessToken = MAPBOX_TOKEN;
}

const DEFAULT_EMERGENCY_CASES = [
  {
    title: 'Suministro Continuo a Planta Potabilizadora',
    description: 'Abastecimiento constante de hipoclorito de sodio para mantener la calidad del agua en plantas de tratamiento.',
    imageKey: 'case-water-treatment.jpg',
    fallbackImage: 'https://pblcrmixiiaautonizbc.supabase.co/storage/v1/object/sign/web-images/pota-02.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ3ZWItaW1hZ2VzL3BvdGEtMDIucG5nIiwiaWF0IjoxNzM3NTc5NDk5LCJleHAiOjE3NjkxMTU0OTl9.A573w9nXgP0m8R2Gs2DwybrZfuv8o_JVfPJxMqu-_gI&t=2025-01-22T20%3A58%3A19.907Z',
  },
  {
    title: 'Transporte Seguro de Ácido Sulfúrico',
    description: 'Flota especializada para transportar ácido sulfúrico con la máxima seguridad.',
    imageKey: 'case-acid-transport.jpg',
    fallbackImage: 'https://pblcrmixiiaautonizbc.supabase.co/storage/v1/object/sign/web-images/tranporte-04.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ3ZWItaW1hZ2VzL3RyYW5wb3J0ZS0wNC5wbmciLCJpYXQiOjE3Mzc1Nzk1MjAsImV4cCI6MTc2OTExNTUyMH0.aIt7V9GAN4eAbeTZnBrS65BCf26tPpLxQ9aWNUBig8g&t=2025-01-22T20%3A58%3A40.284Z',
  },
  {
    title: 'Distribución de Gas Cloro',
    description: 'Entrega puntual y confiable de gas cloro en toda la región sureste.',
    imageKey: 'case-gas-distribution.jpg',
    fallbackImage: 'https://pblcrmixiiaautonizbc.supabase.co/storage/v1/object/sign/web-images/gc-02.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ3ZWItaW1hZ2VzL2djLTAyLnBuZyIsImlhdCI6MTczNzU3OTU1OSwiZXhwIjoxNzY5MTE1NTU5fQ.wnJOFxI4vH3MZu35QTWx7OTqIVWyC8L6DgYgQrQwXCw&t=2025-01-22T20%3A59%3A19.662Z',
  },
];

export function ServicesPage() {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<MapboxMap | null>(null);
  const [mapError, setMapError] = useState(false);
  const [emergencyCases, setEmergencyCases] = useState(DEFAULT_EMERGENCY_CASES);

  const loadCaseImages = useCallback(async () => {
    try {
      const imageKeys = DEFAULT_EMERGENCY_CASES.map((c) => c.imageKey);
      const mediaEntries = await getMediaEntries(imageKeys);

      const updatedCases = DEFAULT_EMERGENCY_CASES.map((case_) => ({
        ...case_,
        image: mediaEntries[case_.imageKey]?.url || case_.fallbackImage,
        alt: mediaEntries[case_.imageKey]?.alt || case_.title,
      }));

      setEmergencyCases(updatedCases);
    } catch (error) {
      console.error('Error loading case images:', error);
    }
  }, []);

  useEffect(() => {
    loadCaseImages();
  }, [loadCaseImages]);

  useEffect(() => {
    if (!mapContainer.current) {
      setMapError(true);
      return;
    }

    try {
      const mapInstance = new mapboxgl.Map({
        container: mapContainer.current,
        style: MAP_CONFIG.style,
        center: MAP_CONFIG.center,
        zoom: MAP_CONFIG.zoom,
        minZoom: MAP_CONFIG.minZoom,
        maxZoom: MAP_CONFIG.maxZoom,
        maxBounds: MAP_CONFIG.maxBounds,
        dragRotate: false,
        pitchWithRotate: false,
      });

      mapInstance.dragRotate.disable();
      mapInstance.touchZoomRotate.disableRotation();
      mapInstance.keyboard.disable();

      mapInstance.addControl(
        new mapboxgl.NavigationControl({ showCompass: false }),
        'top-right'
      );

      mapInstance.on('load', () => {
        LOCATIONS.forEach((location) => {
          new mapboxgl.Marker({ color: '#0A2C5E' })
            .setLngLat(location.coordinates)
            .setPopup(
              new mapboxgl.Popup({ closeButton: false, closeOnClick: true }).setHTML(
                `<strong>${location.name}</strong>`
              )
            )
            .addTo(mapInstance);
        });

        ROUTES.forEach((route) => {
          const from = LOCATIONS.find((loc) => loc.name === route.from);
          const to = LOCATIONS.find((loc) => loc.name === route.to);

          if (from && to) {
            mapInstance.addLayer({
              id: `route-${route.from}-${route.to}`,
              type: 'line',
              source: {
                type: 'geojson',
                data: {
                  type: 'Feature',
                  geometry: {
                    type: 'LineString',
                    coordinates: [from.coordinates, to.coordinates],
                  },
                },
              },
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': route.color,
                'line-width': 4,
                'line-opacity': 0.8,
              },
            });
          }
        });
      });

      mapInstance.jumpTo({ center: MAP_CONFIG.center, zoom: MAP_CONFIG.zoom });
      map.current = mapInstance;
    } catch (error) {
      console.error('Error initializing the map:', error);
      setMapError(true);
    }

    return () => {
      if (map.current) map.current.remove();
    };
  }, []);

  return (
    <div className="bg-gray-50">
      <section className="relative bg-gradient-to-br from-brand-blue to-blue-900 py-24 text-white text-center">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-6">DiQV - Red de Distribución</h1>
          <p className="text-lg sm:text-xl mb-8">
            Expertos en logística química para el sureste mexicano.
          </p>
          <div className="flex justify-center gap-4">
            <Link
              to="/contacto"
              className="bg-white text-brand-blue px-6 py-3 rounded-lg font-semibold shadow-lg hover:shadow-xl hover:bg-blue-50"
            >
              Solicitar Información
            </Link>
            <a
              href="https://maps.app.goo.gl/YU4sq8zWVV2hm9jV7"
              target="_blank"
              rel="noopener noreferrer"
              className="border border-white text-white px-6 py-3 rounded-lg font-semibold hover:bg-white/10 shadow-lg hover:shadow-xl"
            >
              Ver Oficinas
            </a>
          </div>
        </div>
      </section>

      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Nuestra Red Logística</h2>
          <div
            ref={mapContainer}
            className="w-full h-[500px] rounded-lg shadow-lg bg-gray-100 relative overflow-hidden"
          >
            {mapError && (
              <div className="absolute inset-0 flex items-center justify-center bg-red-50">
                <p className="text-red-600 font-medium">Error al cargar el mapa. Intente nuevamente.</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">Casos de Éxito</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {emergencyCases.map((case_) => (
              <div key={case_.title} className="bg-gray-50 rounded-lg shadow hover:shadow-lg overflow-hidden">
                <img
                  src={case_.image}
                  alt={case_.alt}
                  className="w-full h-48 object-cover"
                  loading="lazy"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">{case_.title}</h3>
                  <p>{case_.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
