import React from 'react';
import { Shield, FileText, Award } from 'lucide-react';

interface Certification {
  id: string;
  name: string;
  description: string;
  status: 'active' | 'in-process';
  icon: React.ElementType;
  pdfUrl?: string;
}

const CERTIFICATIONS: Certification[] = [
  {
    id: 'nmx-aa',
    name: 'NMX-AA-124-SCFI-2006',
    description: 'Norma Mexicana para el manejo y transporte de hipoclorito de sodio.',
    status: 'active',
    icon: Shield,
    pdfUrl: 'https://www.gob.mx/cms/uploads/attachment/file/166816/NMX-AA-124-SCFI-2006.pdf'
  },
  {
    id: 'sct',
    name: 'Permisos SCT Clase 8',
    description: 'Autorización para el transporte de materiales y residuos peligrosos.',
    status: 'active',
    icon: Award,
    pdfUrl: 'https://www.sct.gob.mx/fileadmin/NOM-011-SCT2-2003.pdf'
  },
  {
    id: 'profepa',
    name: 'Certificación PROFEPA',
    description: 'Certificado de cumplimiento ambiental y manejo responsable.',
    status: 'in-process',
    icon: FileText,
    pdfUrl: 'https://www.gob.mx/profepa/acciones-y-programas/programa-nacional-de-auditoria-ambiental-56432'
  }
];

export function CertificationsPage() {
  return (
    <div className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Nuestras Certificaciones
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Comprometidos con los más altos estándares de calidad y seguridad en el manejo de productos químicos
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {CERTIFICATIONS.map((cert) => {
            const Icon = cert.icon;
            return (
              <div
                key={cert.id}
                className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow"
              >
                <div className="p-6">
                  <div className="w-12 h-12 bg-brand-blue/10 rounded-lg flex items-center justify-center mb-4">
                    <Icon className="w-6 h-6 text-brand-blue" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {cert.name}
                  </h3>
                  <p className="text-gray-600 mb-4">{cert.description}</p>
                  <div className="flex items-center justify-between">
                    <span
                      className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                        cert.status === 'active'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}
                    >
                      {cert.status === 'active' ? 'Vigente' : 'En proceso'}
                    </span>
                    {cert.pdfUrl && (
                      <a
                        href={cert.pdfUrl}
                        className="flex items-center text-brand-blue hover:text-blue-700"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FileText className="w-4 h-4 mr-2" />
                        <span className="text-sm font-medium">Ver documento</span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-16 bg-white rounded-xl shadow-md p-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">
            Compromiso con la Excelencia
          </h2>
          <div className="prose prose-blue max-w-none">
            <p className="text-gray-600 mb-4">
              En DiQV, cada certificación representa nuestro compromiso con la seguridad y la calidad. Desde 2015, hemos trabajado constantemente para mantener y expandir nuestras acreditaciones, garantizando:
            </p>
            <ul className="list-disc list-inside text-gray-600 space-y-2 mb-6">
              <li>Cumplimiento estricto de normas mexicanas e internacionales</li>
              <li>Transporte seguro de materiales peligrosos</li>
              <li>Protección al medio ambiente</li>
              <li>Mejora continua en nuestros procesos</li>
            </ul>
            <p className="text-gray-600">
              Trabajamos diariamente para mantener estos estándares y buscar nuevas certificaciones que respalden nuestro compromiso con la excelencia.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}