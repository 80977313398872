// Get Mapbox token from environment variables
const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_TOKEN;
if (!MAPBOX_TOKEN) {
  console.warn('VITE_MAPBOX_TOKEN not found in environment variables');
}

export { MAPBOX_TOKEN };

export const MAP_CONFIG = {
  style: 'mapbox://styles/mapbox/streets-v12',
  center: [-96.1342, 19.2033] as [number, number], // Veracruz coordinates
  zoom: 6.2,
  minZoom: 5.5,
  maxZoom: 9,
  maxBounds: [ // Mexico bounds
    [-118.0, 14.0], // SW
    [-86.0, 33.0]   // NE
  ]
};

export const LOCATIONS = [
  { name: 'Veracruz', coordinates: [-96.1342, 19.2033] },
  { name: 'Pánuco', coordinates: [-98.1833, 22.05] },
  { name: 'Tuxpan', coordinates: [-97.4, 20.95] },
  { name: 'Poza Rica', coordinates: [-97.45, 20.5333] },
  { name: 'Papantla', coordinates: [-97.3167, 20.45] },
  { name: 'Tampico', coordinates: [-97.85, 22.2553] },
  { name: 'Martínez de la Torre', coordinates: [-97.0667, 20.0667] },
  { name: 'Paso de Ovejas', coordinates: [-96.4333, 19.2833] },
  { name: 'Perote', coordinates: [-97.2333, 19.5667] },
  { name: 'Puebla', coordinates: [-98.1973, 19.0414] },
  { name: 'CDMX', coordinates: [-99.1332, 19.4326] },
  { name: 'Xalapa', coordinates: [-96.9167, 19.5333] },
  { name: 'Córdoba', coordinates: [-96.9333, 18.8833] },
  { name: 'Minatitlán', coordinates: [-94.5333, 17.9833] },
  { name: 'Coatzacoalcos', coordinates: [-94.4167, 18.15] },
  { name: 'Tres Valles', coordinates: [-96.35, 18.25] },
  { name: 'Tuxtepec', coordinates: [-96.1167, 18.1] },
  { name: 'Catemaco', coordinates: [-95.1167, 18.4167] },
  { name: 'Sayula de Alemán', coordinates: [-94.95, 17.8833] },
  { name: 'Cosamaloapan', coordinates: [-95.8, 18.3667] },
  { name: 'Lázaro Cárdenas', coordinates: [-102.2, 17.95] },
  { name: 'Cosoleacaque', coordinates: [-94.6333, 17.9833] },
  { name: 'Tapachula', coordinates: [-92.2667, 14.9] },
  { name: 'Villahermosa', coordinates: [-92.9167, 17.9892] },
  { name: 'Altamira', coordinates: [-97.9333, 22.4] },
  { name: 'Torreón', coordinates: [-103.4028, 25.5461] },
  { name: 'Río Bravo', coordinates: [-98.0833, 25.9833] },
  { name: 'Tuxtla Gutiérrez', coordinates: [-93.1167, 16.75] },
  { name: 'Mérida', coordinates: [-89.6167, 20.9667] },
  { name: 'Tantoyuca', coordinates: [-98.2333, 21.35] },
  { name: 'Naranjos', coordinates: [-97.6833, 21.35] },
  { name: 'Cerro Azul', coordinates: [-97.7333, 21.2] },
  { name: 'Orizaba', coordinates: [-97.1, 18.85] },
  { name: 'Yanga', coordinates: [-96.8, 18.8333] },
  { name: 'Soledad de Doblado', coordinates: [-96.4167, 19.05] },
  { name: 'José Azueta', coordinates: [-95.6667, 18.0667] },
  { name: 'Isla', coordinates: [-95.5333, 17.9333] },
  { name: 'Rodríguez Clara', coordinates: [-95.4, 17.9833] },
  { name: 'Juan Díaz Covarrubias', coordinates: [-95.7667, 17.1667] },
  { name: 'Acayucan', coordinates: [-94.9136, 17.9472] },
  { name: 'Tlacotalpan', coordinates: [-95.6667, 18.6167] },
  { name: 'Alvarado', coordinates: [-95.7667, 18.7667] },
  { name: 'Santiago Tuxtla', coordinates: [-95.3, 18.4667] },
  { name: 'Las Choapas', coordinates: [-94.1, 17.9167] },
  { name: 'Salta Barranca', coordinates: [-94.85, 17.55] },
  { name: 'Agua Dulce', coordinates: [-94.1333, 18.1333] },
  { name: 'Ciudad Hidalgo', coordinates: [-100.5667, 19.6833] },
  { name: 'Oaxaca', coordinates: [-96.7167, 17.0667] },
  { name: 'Pachuca', coordinates: [-98.7333, 20.1167] },
  { name: 'Querétaro', coordinates: [-100.3833, 20.6] },
  { name: 'Saltillo', coordinates: [-101.0, 25.4333] }
];

// Route colors
const ROUTE_COLORS = {
  RED: '#FF0000',
  DARK_RED: '#8B0000',
  GREEN: '#00FF00',
  BLUE: '#0000FF',
  ORANGE: '#FFA500',
  PURPLE: '#800080'
};

export const ROUTES = [
  // Tampico Routes (Red)
  { from: 'Pánuco', to: 'Tampico', color: ROUTE_COLORS.RED },
  { from: 'Tuxpan', to: 'Tampico', color: ROUTE_COLORS.RED },
  { from: 'Poza Rica', to: 'Tampico', color: ROUTE_COLORS.RED },
  { from: 'Papantla', to: 'Tampico', color: ROUTE_COLORS.RED },
  { from: 'Tampico', to: 'Tantoyuca', color: ROUTE_COLORS.DARK_RED },
  { from: 'Tampico', to: 'Naranjos', color: ROUTE_COLORS.DARK_RED },
  { from: 'Tampico', to: 'Cerro Azul', color: ROUTE_COLORS.DARK_RED },

  // Xalapa Routes (Green)
  { from: 'Martínez de la Torre', to: 'Xalapa', color: ROUTE_COLORS.GREEN },
  { from: 'Veracruz', to: 'Xalapa', color: ROUTE_COLORS.GREEN },
  { from: 'Paso de Ovejas', to: 'Xalapa', color: ROUTE_COLORS.GREEN },
  { from: 'Perote', to: 'Xalapa', color: ROUTE_COLORS.GREEN },
  { from: 'Puebla', to: 'Xalapa', color: ROUTE_COLORS.GREEN },
  { from: 'CDMX', to: 'Xalapa', color: ROUTE_COLORS.GREEN },

  // Cosamaloapan Routes (Blue)
  { from: 'Córdoba', to: 'Cosamaloapan', color: ROUTE_COLORS.BLUE },
  { from: 'Minatitlán', to: 'Cosamaloapan', color: ROUTE_COLORS.BLUE },
  { from: 'Coatzacoalcos', to: 'Cosamaloapan', color: ROUTE_COLORS.BLUE },
  { from: 'Tres Valles', to: 'Cosamaloapan', color: ROUTE_COLORS.BLUE },
  { from: 'Tuxtepec', to: 'Cosamaloapan', color: ROUTE_COLORS.BLUE },
  { from: 'Catemaco', to: 'Cosamaloapan', color: ROUTE_COLORS.BLUE },
  { from: 'Sayula de Alemán', to: 'Cosamaloapan', color: ROUTE_COLORS.BLUE },
  { from: 'Cosamaloapan', to: 'José Azueta', color: ROUTE_COLORS.BLUE },
  { from: 'Cosamaloapan', to: 'Isla', color: ROUTE_COLORS.BLUE },
  { from: 'Cosamaloapan', to: 'Rodríguez Clara', color: ROUTE_COLORS.BLUE },
  { from: 'Cosamaloapan', to: 'Juan Díaz Covarrubias', color: ROUTE_COLORS.BLUE },
  { from: 'Cosamaloapan', to: 'Acayucan', color: ROUTE_COLORS.BLUE },
  { from: 'Cosamaloapan', to: 'Tlacotalpan', color: ROUTE_COLORS.BLUE },
  { from: 'Cosamaloapan', to: 'Alvarado', color: ROUTE_COLORS.BLUE },
  { from: 'Cosamaloapan', to: 'Santiago Tuxtla', color: ROUTE_COLORS.BLUE },
  { from: 'Cosamaloapan', to: 'Las Choapas', color: ROUTE_COLORS.BLUE },
  { from: 'Cosamaloapan', to: 'Salta Barranca', color: ROUTE_COLORS.BLUE },
  { from: 'Cosamaloapan', to: 'Agua Dulce', color: ROUTE_COLORS.BLUE },

  // Paso de Ovejas Routes (Orange)
  { from: 'Paso de Ovejas', to: 'Lázaro Cárdenas', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Cosoleacaque', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Tapachula', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Villahermosa', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Altamira', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Torreón', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Río Bravo', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Tuxtla Gutiérrez', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Mérida', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Ciudad Hidalgo', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Oaxaca', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'CDMX', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Puebla', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Pachuca', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Querétaro', color: ROUTE_COLORS.ORANGE },
  { from: 'Paso de Ovejas', to: 'Saltillo', color: ROUTE_COLORS.ORANGE },

  // Córdoba Routes (Purple)
  { from: 'Córdoba', to: 'Orizaba', color: ROUTE_COLORS.PURPLE },
  { from: 'Córdoba', to: 'Yanga', color: ROUTE_COLORS.PURPLE },
  { from: 'Córdoba', to: 'Soledad de Doblado', color: ROUTE_COLORS.PURPLE }
];