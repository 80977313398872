import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import {
  Phone,
  MapPin,
  Shield,
  Truck,
  ChevronRight,
  MessageSquare,
  Menu,
  X,
} from 'lucide-react';
import { ProductsPage } from './pages/ProductsPage';
import { ContactPage } from './pages/ContactPage';
import { CertificationsPage } from './pages/CertificationsPage';
import { ServicesPage } from './pages/ServicesPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { TermsPage } from './pages/TermsPage';

interface ServiceCardProps {
  icon: React.ElementType;
  title: string;
  description: string;
}

// Botón de WhatsApp con colores y textos optimizados
const WhatsAppButton = () => (
  <a
    href="https://wa.me/522282356878?text=¡Buen día DiQV! Necesito info: transporte seguro de químicos, especificaciones hipoclorito NaOCl, suministro H2SO4 y cobertura sureste MX. ¿Envían documentación técnica y comercial? Saludos."
    className="fixed bottom-6 right-6 z-50 flex items-center gap-2 
               bg-green-600 hover:bg-green-700 text-white px-4 py-3 
               rounded-full shadow-lg transition-all hover:scale-105 
               focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
    target="_blank"
    rel="noopener noreferrer"
    aria-label="Contactar por WhatsApp"
    role="button"
  >
    <MessageSquare className="w-5 h-5" />
    {/* Oculto en pantallas muy pequeñas para aprovechar espacio */}
    <span className="hidden sm:inline font-semibold">WhatsApp Chat</span>
  </a>
);

const ServiceCard = ({ icon: Icon, title, description }: ServiceCardProps) => (
  <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-shadow">
    <div className="w-12 h-12 bg-brand-blue-light/10 rounded-lg flex items-center justify-center mb-4">
      <Icon className="w-6 h-6 text-brand-blue" />
    </div>
    <h3 className="text-xl font-semibold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-600 mb-4">{description}</p>
    <Link
      to={`/servicios#${title.toLowerCase().replace(/\s+/g, '-')}`}
      className="flex items-center text-brand-blue hover:opacity-80 font-medium"
    >
      Más información <ChevronRight className="w-4 h-4 ml-1" />
    </Link>
  </div>
);

const HomePage = () => {
  return (
    <div className="flex flex-col">
      {/* Hero Section con mayor contraste y copy reforzado */}
      <div className="relative">
        {/* Capa de color para contraste */}
        <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-brand-blue-light/80" />
        {/* Imagen de fondo */}
        <div
          className="relative h-[400px] sm:h-[500px] bg-cover bg-center"
          style={{
            backgroundImage:
              'url(https://aehxtbnivdsczmwlkyji.supabase.co/storage/v1/object/sign/images-web/neestoralvz_Fotografa_hiperrealista_de_un_camin_cisterna_esta_941cb29a-ec0a-47f9-b357-c537d66fb411_0.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMtd2ViL25lZXN0b3JhbHZ6X0ZvdG9ncmFmYV9oaXBlcnJlYWxpc3RhX2RlX3VuX2NhbWluX2Npc3Rlcm5hX2VzdGFfOTQxY2IyOWEtZWMwYS00N2Y5LWIzNTctYzUzN2Q2NmZiNDExXzAucG5nIiwiaWF0IjoxNzM3NTY0NDY4LCJleHAiOjE3NjkxMDA0Njh9.pIoK_KydKIDmh935GR27JPefdYJEOhSx-Q-tF8C1lFo&t=2025-01-22T16%3A47%3A48.432Z)',
          }}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
            <div className="max-w-2xl">
              <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6 text-white 
                             drop-shadow-[0_2px_4px_rgba(0,0,0,0.8)] leading-tight">
                Soluciones Químicas Industriales Confiables
              </h1>
              <p className="text-lg sm:text-xl md:text-2xl mb-6 sm:mb-8 text-white/95 
                            drop-shadow-[0_2px_2px_rgba(0,0,0,0.6)] leading-snug">
                Distribución y transporte seguro de químicos en todo el sureste mexicano.
                Experiencia, compromiso y atención personalizada.
              </p>
              <div className="flex flex-wrap gap-4">
                <Link
                  to="/productos"
                  className="bg-white text-brand-blue px-4 sm:px-6 py-2 sm:py-3 rounded-lg 
                             font-semibold hover:bg-blue-50 transition-colors shadow-lg 
                             hover:shadow-xl text-sm sm:text-base"
                >
                  Ver Productos
                </Link>
                <Link
                  to="/contacto"
                  className="bg-brand-blue/90 border-2 border-white text-white px-4 sm:px-6 
                             py-2 sm:py-3 rounded-lg font-semibold hover:bg-brand-blue 
                             transition-colors shadow-lg hover:shadow-xl text-sm sm:text-base"
                >
                  Habla con un Experto
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sección de Servicios Principales */}
      <section
        id="servicios"
        className="py-12 sm:py-16 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-8 sm:mb-12 text-center">
          Nuestros Servicios Principales
        </h2>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          <ServiceCard
            icon={Shield}
            title="Tratamiento de Agua"
            description="Hipoclorito, gas cloro y productos especializados para el cuidado y limpieza del agua en industrias y municipios."
          />
          <ServiceCard
            icon={Truck}
            title="Transporte de Químicos"
            description="Flota certificada para el traslado seguro de ácido sulfúrico y otros productos peligrosos."
          />
          <ServiceCard
            icon={MapPin}
            title="Cobertura Regional"
            description="Veracruz, Chiapas, Tamaulipas, Michoacán y más. Llegamos donde tu negocio nos necesite."
          />
        </div>
      </section>

      {/* CTA de Contacto */}
      <section className="bg-brand-blue py-12 sm:py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl sm:text-3xl font-bold text-white mb-4 drop-shadow-lg">
            ¿Tiene alguna duda?
          </h2>
          <p className="text-lg sm:text-xl text-white/90 mb-8">
            Nuestro equipo le asesorará en todas sus consultas y requerimientos.
          </p>
          <div className="flex justify-center items-center gap-4 sm:gap-6 flex-wrap">
            <a
              href="tel:+522282356878"
              className="flex items-center gap-2 bg-white text-brand-blue px-4 sm:px-6 
                         py-2 sm:py-3 rounded-lg font-semibold hover:bg-blue-50 
                         transition-colors shadow-lg hover:shadow-xl text-sm sm:text-base"
              aria-label="Llamar a DiQV"
            >
              <Phone className="w-5 h-5" />
              <span>Llamar ahora</span>
            </a>
            <Link
              to="/contacto"
              className="flex items-center gap-2 bg-brand-blue/90 border-2 border-white text-white 
                         px-4 sm:px-6 py-2 sm:py-3 rounded-lg font-semibold hover:bg-brand-blue 
                         transition-colors shadow-lg hover:shadow-xl text-sm sm:text-base"
            >
              Solicitar Cotización
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        {/* Encabezado con menú */}
        <header className="bg-white shadow-sm sticky top-0 z-50">
          <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 sm:h-20 flex items-center justify-between">
            {/* Logo e Identidad */}
            <div className="flex items-center">
              <Link to="/" className="flex items-center">
                <img
                  src="https://aehxtbnivdsczmwlkyji.supabase.co/storage/v1/object/sign/images-web/logo.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMtd2ViL2xvZ28ucG5nIiwiaWF0IjoxNzM3NTYzNzEwLCJleHAiOjE3NjkwOTk3MTB9.4_MEbaswJxb4OT61ItKzdg5LwH3SwAPr-04J8SlyxFA&t=2025-01-22T16%3A35%3A10.660Z"
                  alt="Distribuidora de Químicos de Veracruz"
                  className="h-8 sm:h-10 w-auto mr-2 sm:mr-3"
                />
                <span className="text-lg sm:text-xl font-bold text-gray-900 hidden sm:block">
                  Distribuidora de Químicos de Veracruz
                </span>
              </Link>
            </div>

            {/* Botón menú móvil */}
            <button
              onClick={toggleMenu}
              className="md:hidden p-2 rounded-lg hover:bg-gray-100 transition-colors"
              aria-label={isMenuOpen ? 'Cerrar menú' : 'Abrir menú'}
            >
              {isMenuOpen ? (
                <X className="w-6 h-6 text-gray-700" />
              ) : (
                <Menu className="w-6 h-6 text-gray-700" />
              )}
            </button>

            {/* Menú de escritorio */}
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/" className="text-gray-700 hover:text-brand-blue font-medium">
                Inicio
              </Link>
              <Link
                to="/productos"
                className="text-gray-700 hover:text-brand-blue font-medium"
              >
                Productos
              </Link>
              <Link
                to="/servicios"
                className="text-gray-700 hover:text-brand-blue font-medium"
              >
                Servicios
              </Link>
              <Link
                to="/certificaciones"
                className="text-gray-700 hover:text-brand-blue font-medium"
              >
                Certificaciones
              </Link>
              <Link
                to="/contacto"
                className="text-gray-700 hover:text-brand-blue font-medium"
              >
                Contacto
              </Link>
            </div>
          </nav>

          {/* Menú móvil */}
          <div
            className={`md:hidden ${
              isMenuOpen ? 'block' : 'hidden'
            } bg-white border-t border-gray-100`}
          >
            <div className="px-4 pt-2 pb-3 space-y-1">
              <Link
                to="/"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700
                           hover:text-brand-blue hover:bg-gray-50"
                onClick={() => setIsMenuOpen(false)}
              >
                Inicio
              </Link>
              <Link
                to="/productos"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700
                           hover:text-brand-blue hover:bg-gray-50"
                onClick={() => setIsMenuOpen(false)}
              >
                Productos
              </Link>
              <Link
                to="/servicios"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700
                           hover:text-brand-blue hover:bg-gray-50"
                onClick={() => setIsMenuOpen(false)}
              >
                Servicios
              </Link>
              <Link
                to="/certificaciones"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700
                           hover:text-brand-blue hover:bg-gray-50"
                onClick={() => setIsMenuOpen(false)}
              >
                Certificaciones
              </Link>
              <Link
                to="/contacto"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700
                           hover:text-brand-blue hover:bg-gray-50"
                onClick={() => setIsMenuOpen(false)}
              >
                Contacto
              </Link>
            </div>
          </div>
        </header>

        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/productos" element={<ProductsPage />} />
            <Route path="/certificaciones" element={<CertificationsPage />} />
            <Route path="/servicios" element={<ServicesPage />} />
            <Route path="/contacto" element={<ContactPage />} />
            <Route path="/privacidad" element={<PrivacyPage />} />
            <Route path="/terminos" element={<TermsPage />} />
          </Routes>
        </main>

        {/* Footer */}
        <footer className="bg-gray-900 text-gray-400 py-8 sm:py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
              <div>
                <div className="flex items-center text-white mb-4">
                  <Truck className="w-6 h-6" />
                  <span className="ml-2 text-xl font-bold">DiQV</span>
                </div>
                <p className="text-sm">
                  Expertos en soluciones químicas industriales desde 2015
                </p>
              </div>
              <div>
                <h3 className="text-white font-semibold mb-4">Contacto</h3>
                <ul className="space-y-2 text-sm">
                  <li>Juan Escutia 7, Col. 21 de Marzo, Xalapa, Ver.</li>
                  <li>
                    <a
                      href="https://maps.app.goo.gl/YU4sq8zWVV2hm9jV7"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-white transition-colors"
                    >
                      Ver en Google Maps
                    </a>
                  </li>
                  <li>Tel: +52 228 235 6878</li>
                  <li>
                    <a
                      href="mailto:contacto@diqv.mx"
                      className="hover:text-white transition-colors"
                    >
                      Email: contacto@diqv.mx
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-white font-semibold mb-4">Legal</h3>
                <ul className="space-y-2 text-sm">
                  <li>RFC: DQV1502193J5</li>
                  {/* Enlace al Aviso de Privacidad con scroll al tope */}
                  <li>
                    <Link
                      to="/privacidad"
                      className="hover:text-white transition-colors"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Aviso de Privacidad
                    </Link>
                  </li>
                  {/* Enlace a Términos y Condiciones con scroll al tope */}
                  <li>
                    <Link
                      to="/terminos"
                      className="hover:text-white transition-colors"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Términos y Condiciones
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-white font-semibold mb-4">Certificaciones</h3>
                <ul className="space-y-2 text-sm">
                  <li>NMX-AA-124-SCFI-2006</li>
                  <li>Permisos SCT Clase 8</li>
                  <li>Certificación PROFEPA (en proceso)</li>
                </ul>
              </div>
            </div>
            <div className="border-t border-gray-800 mt-8 sm:mt-12 pt-8 text-sm text-center">
              © {new Date().getFullYear()} Distribuidora de Químicos de Veracruz. Todos los derechos reservados.
            </div>
          </div>
        </footer>

        {/* Botón flotante de WhatsApp */}
        <WhatsAppButton />
      </div>
    </Router>
  );
}

export default App;
